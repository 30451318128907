import { TooltipOverlay } from "../../components/TooltipOverlay";
import React, { forwardRef, useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import moment from "moment";
import dagre from "dagre";
import { post } from "../../networking/RequestService";
import * as util from "../../app/functions/util";
import clsx from "clsx";
import { XXL_BREAKPOINT } from "app/popleads/app/contants";

export const CustomToggleIcon = forwardRef(({ onClick, children }, ref) => (
    <span
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </span>
));

export const isActivityOverdue = (dueDate) => {
    const today = moment().format("YYYY-MM-DD");
    return moment(dueDate).isBefore(today);
};

//export modified data to parent component

export const CustomActivityName = ({
    row,
    mobile,
    setActivityId,
    setIsActivityDetailDrawerShown,
    setIsActivityDrawerOpen,
    setSelectedActivity,
    setMainActivityData,
    user,
    setIsApproveDrawerShown,
    setCanActivityDrawerClose,
    setShowCampaignSidebar,
    setIsNotesDrawerShown,
}) => {
    return (
        <div
            className="d-flex justify-content-between cursor-pointer"
            onClick={() => {
                setSelectedActivity(row);
                setMainActivityData(row);
                setShowCampaignSidebar(false);
                setActivityId(row?.id);
                if (!mobile) {
                    setIsActivityDrawerOpen(true);
                    setCanActivityDrawerClose(true);
                } else {
                    setIsActivityDetailDrawerShown(true);
                    setIsNotesDrawerShown(true);
                }
            }}
        >
            <div>
                <TooltipOverlay placement="top" tooltip={row?.subject}>
                    <div
                        className={`activity-name table-text ${
                            isActivityRead(row, user?.id) ? "" : "font-weight-bold"
                        } line-clamp-1 color-secondary`}
                        style={{ wordBreak: "break-all" }}
                    >
                        {row?.subject}
                    </div>
                </TooltipOverlay>
                <div className="text-muted table-sub-text">{"By " + row?.kullaniciName}</div>
            </div>
            {mobile && (
                <div className="d-flex flex-column ml-3">
                    {AmIApprover(user, row) ? (
                        row.approved ? (
                            <SVG src={toAbsoluteUrl("/media/svg/popleads/approved.svg")} className="fill-white" />
                        ) : (
                            <input
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsApproveDrawerShown(true);
                                }}
                                type="radio"
                                // checked={}
                                // onChange={() => setIsApproveDrawerShown(true)}
                                // className="kanban-card-radio-button"
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    accentColor: "#305073",
                                }}
                            />
                        )
                    ) : null}
                    {row.attachmentCount > 0 && (
                        <SVG
                            stroke="#305073"
                            src={toAbsoluteUrl("/media/svg/popleads/attachment.svg")}
                            className="mt-1"
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export const CustomCampaign = ({ row, user, setCampaignDATA, setCampaignModalStatus }) => {
    const [showAll, setShowAll] = useState(false);

    const hasOverflow = row.campaigns?.length > 2;
    const campaignsToDisplay = showAll ? row.campaigns : row.campaigns?.slice(0, 2);
    return (
        <div className="d-flex flex-column" style={{ gap: "6px" }}>
            {campaignsToDisplay ? (
                <>
                    {campaignsToDisplay.map((campaign, index) => {
                        const campaignName = campaign.campaignName
                            ? campaign.campaignDate
                                ? `${campaign.campaignName} (${campaign.campaignDate})`
                                : campaign.campaignName
                            : "-";
                        return (
                            <div key={index} className="d-flex w-100 align-items-center">
                                <div>
                                    {row.campaignId !== null && (
                                        <span
                                            className="mr-4 align-self-center cursor-pointer"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setCampaignModalStatus(true);
                                                setCampaignDATA([campaign]);
                                            }}
                                        >
                                            <SVG
                                                stroke={"#305073"}
                                                src={toAbsoluteUrl("/media/svg/icons/General/info.svg")}
                                                width="18px"
                                                height="18px"
                                            />
                                        </span>
                                    )}
                                </div>
                                <div key={campaign.campaignId}>
                                    <TooltipOverlay placement="top" tooltip={campaignName}>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={
                                                user?.company?.companyConfig?.oldCrmPopleadsHttpLink +
                                                "/?campaignId=" +
                                                campaign.campaignId
                                            }
                                        >
                                            <div
                                                className={clsx(
                                                    "activity-campaign-name table-text color-secondary",
                                                    isActivityRead(row, user?.id) && "font-weight-semi-bold"
                                                )}
                                            >
                                                {campaignName}
                                            </div>
                                        </a>
                                    </TooltipOverlay>
                                    <div className="text-muted table-sub-text">
                                        {campaign.campaignType}
                                        {" - "}
                                        <span className="font-weight-bold">{campaign.campaignStatus}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {hasOverflow && (
                        <p
                            className="primary-color just-small-text cursor-pointer"
                            onClick={() => {
                                setShowAll((prevState) => !prevState);
                            }}
                        >
                            <u>{showAll ? "Show Less" : "Show More"}</u>
                        </p>
                    )}
                </>
            ) : (
                "-"
            )}
        </div>
    );
};

export const CustomCourseName = ({ row, user }) => {
    return (
        <div
            className={`
        `}
        >
            {row.activityCampaignCourseName ? (
                <>
                    <TooltipOverlay placement="top" tooltip={row.activityCampaignCourseName}>
                        <div
                            className={clsx(
                                "activity-campaign-name table-text color-secondary",
                                isActivityRead(row, user?.id) && "font-weight-semi-bold"
                            )}
                        >
                            {row.activityCampaignCourseName}
                        </div>
                    </TooltipOverlay>
                </>
            ) : (
                <>-</>
            )}
        </div>
    );
};

export const CustomActivityType = ({ row, userId }) => {
    return (
        <div
            className={`activity-type table-text  ${isActivityRead(row, userId) ? "" : "font-weight-semi-bold"}
color-secondary 

        
        
        `}
        >
            {row.type}
        </div>
    );
};
export const CustomDueDate = ({ row, userId }) => {
    return (
        <div
            className={`due-date table-text
    `}
        >
            <div
                className={`  ${isActivityRead(row, userId) ? "" : "font-weight-semi-bold"}
                    ${isActivityOverdue(row?.dueDate) ? "text-decoration-line-through   disable-color" : ""}
                        `}
            >
                {" "}
                {moment(row?.dueDate).format("ll")}
            </div>

            {isActivityOverdue(row?.dueDate) && <div className="color-error font-weight-bold">Overdue</div>}
        </div>
    );
};

export const CustomStartDate = ({ row, userId }) => {
    return (
        <div
            className={`due-date table-text
    `}
        >
            <div className={`  ${isActivityRead(row, userId) ? "" : "font-weight-semi-bold"}`}>
                {" "}
                {moment(row?.startDate).format("MMM DD, YYYY")}
            </div>
        </div>
    );
};

export const customStyles = {
    table: {
        style: {
            // maxHeight: "500px",
            // maxHeight: `${rowHeight * 9}px`,
            overflowY: "auto",
            borderRadius: "12px",
        },
    },
    headCells: {
        style: {
            color: "#305073",
            fontSize: "12px",
            fontWeight: "600",
            position: "sticky",
        },
    },
    rows: {
        style: {
            paddingTop: "12px",
            paddingBottom: "12px",
            display: "flex",
            alignItems: "flex-start",
            backgroundColor: "#fcfcfc",
        },
    },

    headRow: {
        style: {
            backgroundColor: "#fcfcfc",
        },
    },
};

export const getConditionalRowStyles = (isActivityDrawerOpen, selectedActivityId) => [
    {
        when: (row) => isActivityDrawerOpen && row.id === selectedActivityId,
        style: { backgroundColor: "#d5e5f8" },
    },
];

export const getLayoutedElements = (nodes, edges, direction = "LR") => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 340;
    const nodeHeight = 60;

    dagreGraph.setGraph({
        rankdir: direction,
    });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = "left";
        node.sourcePosition = "right";

        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });
    return { nodes, edges };
};

export const getStatusClass = (status) => {
    switch (status) {
        case "To Do":
            return "todo";
        case "In Progress":
            return "inprogress";
        case "Completed":
            return "completed";
        case "Ready for Dev":
            return "readyfordevelopment";
        case "Development":
            return "development";

        case "Ready for Testing":
            return "readyfortesting";
        case "Testing":
            return "testing";
        case "Ready for Prod":
            return "readyforproduction";

        default:
            return "todo";
    }
};

export const getPriorityClass = (priority) => {
    switch (priority) {
        case "Low":
            return "low";
        case "Normal":
            return "normal";
        case "High":
            return "high";
        case "Urgent":
            return "urgent";
        default:
            return "normal";
    }
};
export const DUMMY_ACTIVITY_DETAILS = {
    isSubActivity: false,
    subject: "Sample Activity First Version Design Activity",
    status: "In Progress",
    dueDate: 1682974800000,
    createdBy: "Huseyin Sahin",
    type: "Development",
    duration: "8d 23h 59min",
    campaign: "Sample Campaign",
    assignees: [
        { id: "20", name: "Tekin Evrim" },
        { id: "21", name: "Murat Can Şahin" },
        { id: "22", name: "Özgür Doğru" },
        { id: "23", name: "Hüseyin Şahin" },
    ],
    followers: [
        { id: "20", name: "Tekin Evrim" },
        { id: "21", name: "Murat Can Şahin" },
        { id: "22", name: "Özgür Doğru" },
        { id: "23", name: "Hüseyin Şahin" },
    ],
    priority: "Urgent",
    tags: [
        { id: 11, color: "#34c5cb", tag: "leoron", status: true },
        { id: 22, color: "#34c5cb", tag: "leoron KSA", status: true },
        { id: 33, color: "#34c5cb", tag: "leoron Dubai", status: false },
        { id: 44, color: "#34c5cb", tag: "taleem", status: false },
        { id: 55, color: "#34c5cb", tag: "gubretas", status: false },
        { id: 66, color: "#34c5cb", tag: "crm", status: false },
        { id: 77, color: "#34c5cb", tag: "popleads", status: false },
    ],
    description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt scelerisque dui dignissim luctus. Aenean vitae augue ut mi facilisis facilisis nec ac massa. Vivamus in orci at arcu dignissim pellentesque ac sit amet sapien. Vivamus in at arcu dignissim pellentesque sappen.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt scelerisque dui dignissim luctus. Aenean vitae augue ut mi facilisis facilisis nec ac massa. Vivamus in orci at arcu dignissim pellentesque ac sit amet sapien. Vivamus in at arcu dignissim pellentesque sappen.",
    subActivities: [
        {
            id: 1,
            activityname: "Design Analyze",
            activitytype: "In-House Confirmation",
            assignees: [
                { id: 11, name: "Altay Seyfula" },
                { id: "21", name: "Murat Can Şahin" },
            ],
            duedate: "2021-10-10",
            status: "To Do",
            campaigntype: "LVT In-House",
            campaign: "SIPM Dubai November 2022",
            subactivity: true,
            duration: "320",
        },
        {
            id: 2,
            activityname: "Design First Version Analyze",
            activitytype: "In-House Confirmation",
            assignees: [
                { id: 11, name: "Altay Seyfula" },
                { id: "21", name: "Murat Can Şahin" },
                { id: "22", name: "Özgür Doğru" },
            ],
            duedate: "2021-10-10",
            status: "Completed",
            campaigntype: "LVT In-House",
            campaign: "SIPM Dubai November 2022",
            subactivity: true,
            duration: "500",
        },
    ],
};

export const getFileTypeIcon = (filename) => {
    const extension = filename.split(".").pop();
    switch (extension) {
        case "pdf":
            return "/media/svg/popleads/pdf-red.svg";
        case "jpg":
            return "/media/svg/popleads/jpg.svg";
        case "png":
            return "/media/svg/popleads/png.svg";
        case "xls" || "xlsx":
            return "/media/svg/popleads/export_excel_icon.svg";
        default:
            return "/media/svg/files/doc.svg";
    }
};
export const getWindowSize = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};
export const decideColor = (tableName) => {
    switch (tableName) {
        case "To Do":
            return "#8A9EB4";
        case "In Progress":
            return "#CA8A5E";
        case "Completed":
            return "#56CCB4";
        case "Ready for Dev":
            return "#6490BF";
        case "Development":
            return "#305073";
        case "Ready for Testing":
            return "#DB6671";
        case "Testing":
            return "#B93D48";
        case "Ready for Prod":
            return "#7263BF";
        default:
            return "#305073";
    }
};

export const convertMinutesToDHM = (minutes) => {
    const oneDay = 1440; // number of minutes in one day
    const oneHour = 60; // number of minutes in one hour
    const days = Math.floor(minutes / oneDay);
    const hours = Math.floor((minutes % oneDay) / oneHour);
    const mins = minutes % oneHour;
    let result = "";
    if (days > 0) {
        result += `${days}d `;
    }
    if (hours > 0) {
        result += `${hours}h `;
    }
    if (mins > 0 || result === "") {
        result += `${mins}m`;
    }
    return result;
};
export const campaignListTabs = [
    {
        name: "Filter",
        icon: "/media/svg/icons/Text/Filters2.svg",
        count: 0,
    },
    {
        name: "Campaigns",
        icon: "/media/svg/icons/Navigation/thumbs-up.svg",
        count: 0,
    },
];
export const isMobile = () => {
    return getWindowSize().width <= XXL_BREAKPOINT;
};
export const decideTimeColor = (time) => {
    return time < 60 ? " normal" : time < 1440 ? " high" : " completed";
};
///ms to date
export const formatDate = (timestamp, isMobile = false) => {
    const date = new Date(timestamp);
    const options = {
        weekday: "short",
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
    };

    if (isMobile) {
        options.year = "2-digit";
        options.month = "2-digit";
        options.day = "2-digit";
        options.hour12 = true;
        options.weekday = undefined;
    }

    return date.toLocaleDateString("en-US", options);
};

// date to ms
export const convertDateToMs = (date) => {
    return new Date(date).getTime();
};
export const decideFileType = (filename) => {
    let fileExtension = filename.split(".").pop();
    if (fileExtension === "pdf") {
        return "/media/svg/popleads/pdf.svg";
    } else if (
        fileExtension === "jpg" ||
        fileExtension === "png" ||
        fileExtension === "jpeg" ||
        fileExtension === "png" ||
        fileExtension === "gif" ||
        fileExtension === "tiff" ||
        fileExtension === "tif" ||
        fileExtension === "PNG"
    ) {
        return "/media/svg/popleads/image.svg";
    } else if (fileExtension === "xls" || fileExtension === "xlsx" || fileExtension === "xltx") {
        return "/media/svg/popleads/xls.svg";
    } else if (fileExtension === "csv") {
        return "/media/svg/popleads/csv.svg";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
        return "/media/svg/popleads/doc.svg";
    } else if (fileExtension === "eml") {
        return "/media/svg/popleads/eml.svg";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
        return "/media/svg/popleads/ppt.svg";
    } else if (fileExtension === "txt") {
        return "/media/svg/popleads/txt.svg";
    } else if (fileExtension === "mp3" || fileExtension === "mp4" || fileExtension === "wav") {
        return "/media/svg/popleads/sounds.svg";
    } else if (fileExtension === "zip" || fileExtension === "rar") {
        return "/media/svg/popleads/zip.svg";
    } else if (
        fileExtension === "mov" ||
        fileExtension === "avi" ||
        fileExtension === "wmv" ||
        fileExtension === "mpg"
    ) {
        return "/media/svg/popleads/video.svg";
    } else if (fileExtension === "msg") {
        return "/media/svg/popleads/msg.svg";
    } else if (fileExtension === "eml") {
        return "/media/svg/popleads/eml.svg";
    }
};
export const calculateDuration = (start, end) => {
    const startParts = start.split(":");
    const endParts = end.split(":");

    const startHour = parseInt(startParts[0]);
    const startMinute = parseInt(startParts[1]);
    const endHour = parseInt(endParts[0]);
    const endMinute = parseInt(endParts[1]);

    let hourDiff = endHour - startHour;
    let minuteDiff = endMinute - startMinute;

    if (hourDiff < 0) {
        hourDiff += 24;
    }

    if (minuteDiff < 0) {
        minuteDiff += 60;
        hourDiff--;
    }

    const hours = hourDiff > 0 ? `${hourDiff}h` : "";
    const minutes = minuteDiff > 0 ? `${minuteDiff}m` : "";

    return `${hours}${minutes}`;
};
export const loadOptions = (inputValue, callback, searchAll, setCampaignOptions) => {
    const requestObj = {
        fn: "etiket_ara",
        kelime: inputValue,
    };
    if (searchAll) requestObj.searchAll = searchAll;
    post(requestObj)
        .then((res) => {
            const options = res.data.liste.map((item) => ({
                value: item.id,
                label: item.ad,
            }));
            callback(options);
            setCampaignOptions(options);
        })
        .catch(() => {
            callback([]);
        });
};
export const onAddDuration = ({
    singleDuration,
    setSingleDuration,
    selectedDurationTab,
    durationInput,
    setDurationInput,
    rangeInputs,
    setRangeInputs,
    activityId,
    mobile,
    setErrorMessage,
    setIsDurationDrawerOpen,
    setShowDurationDropdown,
    refreshData,
    setRefreshData,
    errorMessage,
}) => {
    if (
        selectedDurationTab === "duration"
            ? !durationInput || !singleDuration
            : !rangeInputs?.start || !rangeInputs?.end || !singleDuration
    ) {
        setErrorMessage("Please fill all the required fields");
    } else {
        let addDurationObj = {
            fn: "insertActivityDuration",
            activityId: activityId,
            durationDate: moment(singleDuration).format("YYYY-MM-DD"),
            duration:
                selectedDurationTab === "duration"
                    ? durationInput
                    : calculateDuration(rangeInputs.start, rangeInputs.end),
        };
        /// if duration input does include any letter h or m console log error
        const durationPattern = /^(\d+h)?\s*(\d+m)?$/;

        if (selectedDurationTab === "duration" && durationInput?.length > 0 && !durationPattern.test(durationInput)) {
            setErrorMessage("Please enter a valid duration");
            return;
        }
        if (selectedDurationTab === "range") {
            if (!rangeInputs?.start || !rangeInputs?.end || rangeInputs?.start == rangeInputs?.end) {
                setErrorMessage("Please enter a valid duration");

                return;
            }
        }
        post(addDurationObj).then((res) => {
            mobile ? setIsDurationDrawerOpen(false) : setShowDurationDropdown(false);
            setSingleDuration("");
            setDurationInput("");
            setRefreshData(!refreshData);
            setErrorMessage(null);
            setRangeInputs({
                start: "",
                end: "",
            });
        });
    }
};
export const isActivityRead = (row, userId) => {
    if (row) {
        const readByUserIds = JSON.parse(row.readByUserIds);
        return readByUserIds?.includes(userId);
    }
};
export const markActivityAsOpened = (activityId) => {
    let markActivityAsOpenedObj = {
        fn: "markActivityAsOpened",
        activityId: activityId,
    };
    post(markActivityAsOpenedObj);
};
export const activityMarkAsUnread = (
    activityId,
    setChangedFields = () => {},
    userId,
    mobile,
    fetchData,
    setFetchData
) => {
    let markActivityAsUnreadObj = {
        fn: "activityMarkAsUnread",
        activityId: activityId,
    };
    post(markActivityAsUnreadObj).then((res) => {
        mobile
            ? setFetchData(!fetchData)
            : setChangedFields({
                  id: activityId,
                  unread: true,
                  readByUserIds: userId,
              });
    });
};
export const typeDependentAddAssignees = (
    typeOfActivity,
    assignees,
    setAssignees,
    setActivityAddModalAssignees,
    allAssignees,
    loggedUser
) => {
    let groups = allAssignees?.filter((item) => item.isGroup === true);
    //let users = allAssignees?.filter(item => item.isGroup !== true);
    if (typeOfActivity === "In-house Inquiry") {
        const foundedGroup = groups.filter((group) => group.name === "In-house Inquiry ID");
        setAssignees(foundedGroup);
        setActivityAddModalAssignees(
            foundedGroup?.map((assignee) => ({
                ...assignee,
                value: assignee.id,
                id: assignee.id,
                label: assignee.name,
            }))
        );
    }
    if (typeOfActivity === "Invoice Requisition") {
        const foundedGroup = groups.filter((group) => group.name === "Invoice Requisition");
        const addedUsers = foundedGroup.concat([loggedUser]);
        setAssignees(addedUsers);
        // setActivityAddModalAssignees(addedUsers);
        setActivityAddModalAssignees(
            addedUsers?.map((assignee) => ({
                ...assignee,
                value: assignee.id,
                id: assignee.id,
                label: assignee.name,
            }))
        );
    }

    if (typeOfActivity === "In-house Confirmation") {
        const foundedGroup1 = groups.filter((group) => group.name === "InHouse Confirmation Team");
        const foundedGroup2 = groups.filter((group) => group.name === "Invoice Requisition");
        const foundedGroups = foundedGroup1.concat(foundedGroup2);
        setAssignees(foundedGroups);
        setActivityAddModalAssignees(
            foundedGroups?.map((assignee) => ({
                ...assignee,
                value: assignee.id,
                id: assignee.id,
                label: assignee.name,
            }))
        );
    }

    if (typeOfActivity === "Consulting Confirmation") {
        const foundedGroup1 = groups.filter((group) => group.name === "Consulting Confirmation Team");
        const foundedGroup2 = groups.filter((group) => group.name === "Invoice Requisition");
        const foundedGroups = foundedGroup1.concat(foundedGroup2);
        setAssignees(foundedGroups);
        setActivityAddModalAssignees(
            foundedGroups?.map((assignee) => ({
                ...assignee,
                value: assignee.id,
                id: assignee.id,
                label: assignee.name,
            }))
        );
    }

    if (typeOfActivity === "eLearning Inquiry") {
        const foundedGroup = groups.filter((group) => group.name === "eLearning Inquiry");
        setAssignees(foundedGroup);
        setActivityAddModalAssignees(
            foundedGroup?.map((assignee) => ({
                ...assignee,
                value: assignee.id,
                id: assignee.id,
                label: assignee.name,
            }))
        );
    }

    if (typeOfActivity === "eLearning Confirmation") {
        const foundedGroup1 = groups.filter((group) => group.name === "eLearning Confirmation Team");
        const foundedGroup2 = groups.filter((group) => group.name === "Invoice Requisition");
        const foundedGroups = foundedGroup1.concat(foundedGroup2);
        setAssignees(foundedGroups);
        setActivityAddModalAssignees(
            foundedGroups?.map((assignee) => ({
                ...assignee,
                value: assignee.id,
                id: assignee.id,
                label: assignee.name,
            }))
        );
    }

    if (typeOfActivity === "Coaching Inquiry") {
        const foundedGroup = groups.filter((group) => group.name === "Coaching Inquiry");
        setAssignees(foundedGroup);
        setActivityAddModalAssignees(
            foundedGroup?.map((assignee) => ({
                ...assignee,
                value: assignee.id,
                id: assignee.id,
                label: assignee.name,
            }))
        );
    }

    if (typeOfActivity === "Coaching Confirmation") {
        const foundedGroup1 = groups.filter((group) => group.name === "Coaching Confirmation Team");
        const foundedGroup2 = groups.filter((group) => group.name === "Invoice Requisition");
        const foundedGroups = foundedGroup1.concat(foundedGroup2);
        setAssignees(foundedGroups);
        setActivityAddModalAssignees(
            foundedGroups?.map((assignee) => ({
                ...assignee,
                value: assignee.id,
                id: assignee.id,
                label: assignee.name,
            }))
        );
    }

    if (typeOfActivity === "Consulting Services") {
        const foundedGroup = groups.filter((group) => group.name === "Consulting Inquiry");
        setAssignees(foundedGroup);
        setActivityAddModalAssignees(
            foundedGroup?.map((assignee) => ({
                ...assignee,
                value: assignee.id,
                id: assignee.id,
                label: assignee.name,
            }))
        );
    }

    if (typeOfActivity === "Delegates List") {
        const foundedGroup = groups.filter((group) => group.name === "Delegates List");
        setAssignees(foundedGroup);
        setActivityAddModalAssignees(
            foundedGroup?.map((assignee) => ({
                ...assignee,
                value: assignee.id,
                id: assignee.id,
                label: assignee.name,
            }))
        );
    }
};
export const handleMoveActivity = (data, selectedCampaigns, onMoveError, onMove) => {
    let editActivityObj = {
        fn: "editActivity",
        activityId: data.id,
        priority: data.priority,
        activityType: data.type,
        campaignId: selectedCampaigns.map((campaign) => campaign.value),
        startDate: data.startDate,
        endDate: data.dueDate,
        description: data.description,
    };
    if (data?.approverId) {
        editActivityObj.approverId = data.approverId;
    }

    const columnsList = data?.columnsList?.filter((item) => {
        if (!!item.fieldValue) return item;
    });
    editActivityObj.columnsKeys = columnsList?.map((item) => item?.schemaId);
    editActivityObj.columnsVals = columnsList?.map((item) => item?.fieldValue);

    post(editActivityObj)
        .then((res) => {
            onMove();
        })
        .catch((err) => {
            onMoveError && onMoveError(err);
        });
};
export const IsCreatedByMe = (user, row) => {
    return user?.id === row?.kullaniciId;
};
export const AmIAssignee = (user, row) => {
    return row?.assignees && row?.assignees?.find((assignee) => assignee.userId === user?.id);
};
export const AmIFollower = (user, row) => {
    return row?.followerIdList?.find((follower) => follower === user?.id);
};
export const AmIApprover = (user, row) => {
    return row?.approverId === user?.id;
};
export const approveActivity = (selectedActivity, fetchData, setFetchData, handleFetchActivityDetailAgain) => {
    let approveActivityObj = {
        fn: "approveActivity",
        activityId: selectedActivity.id,
    };
    post(approveActivityObj).then((res) => {
        setFetchData && setFetchData(!fetchData);
        handleFetchActivityDetailAgain();
    });
};
export const getActivityNotes = (activityId, setNotes, setLoading) => {
    let getActivityNotesObj = {
        fn: "getActivityNotes",
        activityId: activityId,
    };
    setLoading && setLoading(true);
    post(getActivityNotesObj)
        .then((res) => {
            setNotes(res.data.activityNotesList);
        })
        .finally(() => {
            setLoading && setLoading(false);
        });
};

export const maxMentionSuggestions = 10;
export const onNoteChangeWithMentions = (e, setSingleNote, setMentionedUsers) => {
    setSingleNote(e.target.value);
    const value = e.target.value;
    const regex = /\[(.*?)\]\s*\([^)]*\)/g;
    const mentions = value.match(regex);
    setMentionedUsers(
        mentions
            ? mentions.map((mention) => {
                  const id = mention.substring(mention.indexOf("(") + 1, mention.indexOf(")"));
                  const display = mention.substring(mention.indexOf("[") + 1, mention.indexOf("]"));
                  return { id, display };
              })
            : []
    );
};
export const handleDownload = (selectedDocuments, campaignId, activityId, crmURL) => {
    if (selectedDocuments.type === "delegatesList") {
        post({ fn: "getDelegateList", campaignId: campaignId, activityId: activityId }).then((res) => {
            var downloadLink =
                crmURL +
                "/ws/" +
                res.data.fileId +
                "/downloadExport?delegateList=delegateList&fileName=" +
                encodeURIComponent(res.data.fileName);

            const anchorElement = document.createElement("a");
            anchorElement.href = downloadLink;
            anchorElement.download = getFileNameFromURL(downloadLink);
            anchorElement.target = "_blank";
            anchorElement.click();
        });
    } else {
        const downloadLink =
            selectedDocuments.length > 1
                ? selectedDocuments.map((document) => document.downloadLink)
                : selectedDocuments.downloadLink;

        const anchorElement = document.createElement("a");
        anchorElement.href = downloadLink;
        anchorElement.download = getFileNameFromURL(downloadLink);
        anchorElement.target = "_blank";
        anchorElement.click();
    }
};

const getFileNameFromURL = (url) => {
    const pathSegments = url.split("/");
    return pathSegments[pathSegments.length - 1];
};
export const checkFileExtension = (fileName) => {
    var ALLOWED_FILE_EXTS =
        ".xls,.xlsx,.csv,.doc,.docx,.pdf,.jpg,.jpeg,.png,.gif,.ppt,.pptx,.txt,.mp3,.wav,.mp4,.mov,.wmv,.avi,.mpg,.zip,.rar,.msg,.tif,.tiff,.eml,.xltx";
    var allowedExtensions = ALLOWED_FILE_EXTS.split(",");

    var fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1).toLowerCase();

    return allowedExtensions.indexOf("." + fileExtension) !== -1;
};
export const getDocuments = (activityId, setDocumentsList, setErrorMessage, setLoading, activityType) => {
    setLoading(true);

    let documentsObj = {
        fn: "getAttachmentsForActivity",
        activityId: activityId,
    };
    activityId &&
        post(documentsObj).then((res) => {
            setLoading(false);
            let documentList = res.data.attachments.map((doc) => ({
                ...doc,
                isChecked: false,
            }));
            if (activityType === "Delegates List") {
                documentList = [
                    {
                        id: 0,
                        fileId: 0,
                        isChecked: false,
                        filename: "Delegates List for {{campaignName}}.xlsx",
                        date: null,
                        creator: null,
                        type: "delegatesList",
                    },
                    ...documentList,
                ];
            }
            setDocumentsList(documentList);
        });
    setErrorMessage("");
};
export const handleInvReqFileChange = (e, file, selectedFiles, setSelectedFiles) => {
    if (e.target.checked) {
        const updatedFiles = selectedFiles.map((selectedFile) => {
            if (selectedFile.name === file.name) {
                selectedFile.isInvReq = true;
            }
            return selectedFile;
        });

        setSelectedFiles(updatedFiles);
    } else {
        const updatedFiles = selectedFiles.map((selectedFile) => {
            if (selectedFile.name === file.name) {
                selectedFile.isInvReq = false;
            }
            return selectedFile;
        });

        setSelectedFiles(updatedFiles);
    }
};
export const onInputKeyDown = (e, handleSendNote, isSendButtonDisabled, isBottomInput) => {
    if (isBottomInput) {
        if (!isSendButtonDisabled)
            if (e.key === "Enter" && !e.shiftKey) {
                if (e.target.value.trim() === "") {
                    e.preventDefault();
                    return;
                }
                if (e.target.value.split("\n").length > 15) {
                    e.preventDefault();
                    return;
                }
                handleSendNote();
            }
    } else {
        if (e.key === "Enter" && !e.shiftKey) {
            if (e.target.value.trim() === "") {
                e.preventDefault();
                return;
            }
            e.preventDefault();
            handleSendNote();
        }
    }
};
export const getActivityAssignees = (activityId) => {
    let getActivityAssigneesObj = {
        fn: "getActivityAssignees",
        activityId: activityId,
    };

    return new Promise((resolve, reject) => {
        post(getActivityAssigneesObj)
            .then((res) => {
                resolve(res.data.assignees);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getActivityAllUsersByActivityIdForMention = (activityId) => {
    let getActivityAllUsersByActivityIdForMentionObj = {
        fn: "getActivityAllUsersByActivityIdForMention",
        activityId: activityId,
    };
    return new Promise((resolve, reject) => {
        post(getActivityAllUsersByActivityIdForMentionObj)
            .then((res) => {
                let users = [
                    ...(res.data?.assignees || []),
                    ...(res.data?.followers || []),
                    ...(res.data?.approvers || []),
                    ...(res.data?.creators || []),
                ].flat();
                resolve(users);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const checkDynamicFields = (dynamicSchema, dynamicValues) => {
    let isAllRequiredFilled = true;
    dynamicSchema.forEach((schema) => {
        if (schema.isRequired === 1) {
            if (
                dynamicValues[schema.schemaId]?.length === 0 ||
                dynamicValues[schema.schemaId] === "" ||
                !dynamicValues[schema.schemaId]
            ) {
                // console.log("req false field:", schema.fieldName);
                isAllRequiredFilled = false;
            } else {
                // console.log("req true field:", schema.fieldName, "value: ", dynamicValues[schema.schemaId]);
            }
        }
    });
    // console.log("isAllRequiredFilled: ", isAllRequiredFilled);
    return isAllRequiredFilled;
};

export const checkInvReqRequiredFields = (invoiceRequisitionFields, activityType, isEditMode) => {
    let isAllRequiredFilled = true;
    let additionalInputsArr = [];

    Object.keys(invoiceRequisitionFields).map((fieldKey) => {
        const field = invoiceRequisitionFields[fieldKey];
        if (field.value && field.additionalInputs) {
            Object.keys(field.additionalInputs).map((fieldKey) => {
                const additionalInput = field.additionalInputs[fieldKey];
                additionalInput.required && additionalInputsArr.push(additionalInput);
                if (
                    additionalInput.required &&
                    (!additionalInput.value || additionalInput.value === "" || additionalInput.value?.length === 0)
                ) {
                    isAllRequiredFilled = false;
                }
            });
        }
        if (field.required && (!field.value || field.value === "" || field.value?.length === 0)) {
            if (
                (isEditMode ||
                    activityType?.label === "In-house Confirmation" ||
                    activityType?.label === "Coaching Confirmation" ||
                    activityType?.label === "eLearning Confirmation" ||
                    activityType?.label === "Consulting Confirmation") &&
                field.label === "Full Price"
            )
                return;

            isAllRequiredFilled = false;
        } else if (field.inputs) {
            Object.keys(field.inputs).map((inKey) => {
                const inField = field.inputs[inKey];
                if (inField.required && (!inField.value || inField.value === "" || inField.value?.length === 0)) {
                    isAllRequiredFilled = false;
                }
            });
        }
        //else console.log("not required:", field);
    });

    return isAllRequiredFilled;
};

export const createInvReqObj = (invoiceRequisitionFields, unitsDeal, dealId, id) => {
    const queryDealId = util.getUrlQueryParam("dealId");

    const invoiceRequisitionObj = {
        id: id,
        dealId: dealId ? dealId : queryDealId ? queryDealId : null,
        customerName: invoiceRequisitionFields.customerName.value,
        customerTitle: invoiceRequisitionFields.customerTitle.value,
        customerCompany:
            invoiceRequisitionFields.customerCompany && invoiceRequisitionFields.customerCompany.value
                ? invoiceRequisitionFields.customerCompany.value.value
                : "",
        customerAddress: invoiceRequisitionFields.billingAddress.value,
        branchCompanies: invoiceRequisitionFields.leoronBranch.value.map((item) => item.value),
        customerCity: invoiceRequisitionFields.billingCityAndPostalCode.inputs.billingCity.value.value,
        customerPostalCode: invoiceRequisitionFields.billingCityAndPostalCode.inputs.billingPostalCode.value,
        customerState: invoiceRequisitionFields.billingStateAndCountry.inputs.billingState.value,
        customerCountry: invoiceRequisitionFields.billingStateAndCountry.inputs.billingCountry.value.value,
        customerEmail: invoiceRequisitionFields.customerEmail.value,
        customerPhone: invoiceRequisitionFields.customerPhone.value,
        isGovermental: invoiceRequisitionFields.governmental.value,
        customerVatNumber: invoiceRequisitionFields.billingVAT.value,
        dealType: invoiceRequisitionFields.dealType.value.value,
        amountCampaign: invoiceRequisitionFields.campaignAmount.value,
        amountDeal: invoiceRequisitionFields.amount.value,
        unitsDeal, // kaç tane unit olduğu
        totalAmountDeal: invoiceRequisitionFields.totalAmount.value, // amount * unit
        discount: util.calculateDealDiscount(
            invoiceRequisitionFields.campaignAmount.value,
            invoiceRequisitionFields.amount.value,
            unitsDeal
        ),
        finalAmount: invoiceRequisitionFields.fullPrice.value,
        discountType: invoiceRequisitionFields.discountType.value?.value,
        isAccountEdit: invoiceRequisitionFields.saveBillingInfo.value,
        serviceType: invoiceRequisitionFields.needVenue.additionalInputs.serviceType.value?.value
            ? invoiceRequisitionFields.needVenue.additionalInputs.serviceType.value?.value
            : null,
        serviceAmount: invoiceRequisitionFields.needVenue.additionalInputs.serviceAmount.value,
        addInsType: invoiceRequisitionFields.needIns.additionalInputs.serviceType.value?.value
            ? invoiceRequisitionFields.needIns.additionalInputs.serviceType.value?.value
            : null,
        addInsAmount: invoiceRequisitionFields.needIns.additionalInputs.serviceAmount.value,
        numberOfKits: 0,
        buildingNo: invoiceRequisitionFields.buildingNo.value || "",
        street: invoiceRequisitionFields.street.value || "",
        secondaryNo: invoiceRequisitionFields.secondaryNo.value || "",
        district: invoiceRequisitionFields.district.value || "",
        otherBuyerId: invoiceRequisitionFields.otherBuyerID.value || "",
    };

    return JSON.stringify(invoiceRequisitionObj);
};

export const checkEditActivityPermission = (activityData, user) => {
    if (user?.permissions?.ACTIVITY_CHECKPRRIVATEORPUBLICMODE === 1) return true; // super users

    let permittedUsers = [];
    if (activityData?.kullaniciId) permittedUsers.push(activityData.kullaniciId);
    if (activityData?.approverId) permittedUsers.push(activityData.approverId);
    if (activityData?.followerIdList && Array.isArray(activityData.followerIdList))
        permittedUsers = permittedUsers.concat(activityData.followerIdList);

    if (activityData?.assignees && Array.isArray(activityData.assignees)) {
        permittedUsers = permittedUsers.concat(
            activityData.assignees.filter((assignee) => !!assignee.userId).map((assignee) => assignee.userId)
        );

        const isUserInAssignedGroup = util.isUserInThisGroup(
            user,
            activityData.assignees.filter((assignee) => !!assignee.groupId).map((assignee) => assignee.groupId)
        );
        if (isUserInAssignedGroup) return true;
    }

    return !!permittedUsers.includes(user.id);
};

export const createPurchaseReqObj = (purchaseRequisitionFields, id) => {
    let obj = {
        id: id,
        supplierName: purchaseRequisitionFields.supplierName.value,
        supplierContact: purchaseRequisitionFields.supplierContact.value,
        supplierAddress: purchaseRequisitionFields.supplierAddress.value,
        supplierPostalCode: purchaseRequisitionFields.supplierCityAndPostalCode.inputs.postalCode.value,
        supplierCity: purchaseRequisitionFields.supplierCityAndPostalCode.inputs.supplierCity.value,
        supplierState: purchaseRequisitionFields.supplierStateAndCountry.inputs.supplierState.value,
        supplierCountry: purchaseRequisitionFields.supplierStateAndCountry.inputs.country.value,
        supplierEmail: purchaseRequisitionFields.supplierEmail.value,
        supplierPhone: purchaseRequisitionFields.supplierPhone.value,
        amount: purchaseRequisitionFields.amountAndCurrency.inputs.amount.value,
        currency: purchaseRequisitionFields.amountAndCurrency.inputs.currency.value.value,
        paid: purchaseRequisitionFields.paid.value ? 1 : 0,
        paymentType: purchaseRequisitionFields.paymentType.value.value,
        costType: purchaseRequisitionFields.costType.value.value,
    };

    return obj;
};

export function formatDates(start_date, end_date) {
    if (!start_date && !end_date) {
        return "No Date";
    }

    if (!start_date && end_date) {
        const endDate = moment(end_date);
        return "No Date - " + endDate.format("MMMM DD, YYYY");
    }

    if (start_date && !end_date) {
        const startDate = moment(start_date);
        return startDate.format("MMMM DD, YYYY") + " - No Date";
    }

    const startDate = moment(start_date);
    const endDate = moment(end_date);

    if (startDate.year() === endDate.year()) {
        if (startDate.month() === endDate.month()) {
            return startDate.format("MMMM DD") + " - " + endDate.format("DD, YYYY");
        } else {
            return startDate.format("MMMM DD") + " - " + endDate.format("MMMM DD, YYYY");
        }
    } else {
        return startDate.format("MMMM DD, YYYY") + " - " + endDate.format("MMMM DD, YYYY");
    }
}

export const saveSortingPreferences = (user, newOrder) => {
    if (user?.sorting) {
        let editSortingRequest = {
            fn: "editSorting",
            // DEFAULT: '{"activity":[{"column":"startDate","type":"asc"}],"tag":[{"column":"basTarih","type":"asc"}],"deal":[{"column":"ad","type":"desc"}]}'
            sorting: JSON.stringify({
                ...user.sorting,
                activity: [{ column: newOrder.column, type: newOrder.type }],
            }),
        };
        post(editSortingRequest);
    }
};

export const campaignFiltersToSendAPI = (selectedCampaignFilter, selectedCampaignList) => {
    if (selectedCampaignList?.length > 0) {
        return {
            filterCampaignId: selectedCampaignList?.map((campaign) => campaign.value),
        };
    } else {
        const selectedFiltersObj = {
            ...(selectedCampaignFilter?.status?.length > 0 && {
                filterCampaignStatus: selectedCampaignFilter?.status?.map((item) => item.label),
            }),
            ...(selectedCampaignFilter?.campaignType?.length > 0 && {
                filterCampaignType: selectedCampaignFilter?.campaignType?.map((item) => item.label),
            }),
            ...(selectedCampaignFilter?.year?.length > 0 && {
                filterCampaignYear: selectedCampaignFilter?.year?.map((item) => item.label),
            }),
            ...(selectedCampaignFilter?.month?.length > 0 && {
                filterCampaignMonth: selectedCampaignFilter?.month?.map((item) => item.value),
            }),
            ...(selectedCampaignFilter?.week?.length > 0 && {
                filterCampaignWeek: selectedCampaignFilter?.week?.map((item) => item.value),
            }),
            ...(selectedCampaignFilter?.campaignAccount?.length > 0 && {
                campaignAccount: selectedCampaignFilter?.campaignAccount?.map((item) => item.value),
            }),
            ...(selectedCampaignFilter?.campaignPm?.length > 0 && {
                campaignPm: selectedCampaignFilter?.campaignPm?.map((item) => item.value),
            }),
            ...(selectedCampaignFilter?.campaignTrainer?.length > 0 && {
                campaignTrainer: selectedCampaignFilter?.campaignTrainer?.map((item) => item.value),
            }),
            ...(selectedCampaignFilter?.campaignAssistant?.length > 0 && {
                campaignAssistant: selectedCampaignFilter?.campaignAssistant?.map((item) => item.value),
            }),
            ...(selectedCampaignFilter?.campaignCountry?.length > 0 && {
                campaignCountry: selectedCampaignFilter?.campaignCountry?.map((item) => item.value),
            }),
        };

        if (Object.keys(selectedFiltersObj).length > 0) {
            return selectedFiltersObj;
        } else {
            return null;
        }
    }
};
export const returnMentionIdsFromText = (text, mentionableUsers) => {
    const regex = /<span class="mention" data-mention="(.*?)">(.*?)<\/span>/g;
    const mentions = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
        const mention = {
            mentionData: match[1], // Data after the @ symbol
            mentionText: match[2], // Text inside the mention
        };
        mentions.push(mention);
    }
    const mentionedUserIds = [];
    const mentionedGroupIds = [];
    mentions.forEach((mentionedUser) => {
        mentionableUsers.forEach((mentionableUser) => {
            if (mentionedUser.mentionText === mentionableUser.name) {
                if (mentionableUser.isGroup) {
                    mentionedGroupIds.push(mentionableUser.groupId);
                } else {
                    mentionedUserIds.push(mentionableUser.userId);
                }
            }
        });
    });

    return { mentionedUserIds, mentionedGroupIds };
};
export const filterWithSameName = (users) => {
    const filteredUsers = [];
    const filteredArray = users.filter((user, index) => {
        if (filteredUsers.indexOf(user.name) === -1) {
            filteredUsers.push(user.name);
            return true;
        }
        return false;
    });
    return filteredArray;
};
export const isId = (activity, user) => {
    let IdGroup = activity?.assignees?.find((item) => item.assigneeName === "ID Process Automation");
    return user?.groupIds?.includes(IdGroup?.groupId);
};
export const handleDocumentApproveSelect = (
    selectedActivity,
    document,
    status,
    toggleRefetchDocuments,
    setDocumentStatus,
    toast
) => {
    let approveMaterialObj = {
        fn: "approveMaterial",
        activityId: selectedActivity?.id,
        attachmentId: document.id,
        status: status,
    };

    if (status === "publish" && selectedActivity.campaigns[0].campaignType === "In-house") {
        toast &&
            toast("This campaign type is In-house, so the document will not be published.", {
                type: "error",
            });
        return;
    } else {
        post(approveMaterialObj)
            .then((response) => {
                setDocumentStatus(status === "publish" ? "Published" : status === "approved" ? "Approve" : "Re-Work");
                toggleRefetchDocuments();
            })
            .catch((error) => {
                toast &&
                    toast(error?.response?.data?.text ?? "Something went wrong", {
                        type: "error",
                    });
                setDocumentStatus(null);
            });
    }
};

export const isAllDocumentsApproved = (documentsList) => {
    for (let i = 0; i < documentsList?.length; i++) {
        if (documentsList[i].status === null) {
            return false;
        }
    }
    return true;
};
export const decideIsStrikeThroughOrNot = (selectedActivity, singleUserObj) => {
    if (selectedActivity.status !== "Completed") {
        return false;
    }
    return !(
        selectedActivity.type !== "In-House Materials" &&
        selectedActivity.assignees.some((item) => item.assigneeName === "Design Department") &&
        singleUserObj[0]?.groupIds?.includes(selectedActivity.assignees[0]?.groupId)
    );
};

export const getCourseName = (campaign) => {
    let fullCourseName;
    const courseName = util.getDynamicValue(campaign?.columnsList, "Course Name");
    const subCourseName = campaign?.subCourseName;
    if (subCourseName) {
        fullCourseName = `${courseName} - ${subCourseName}`;
    } else {
        fullCourseName = courseName;
    }
    return fullCourseName;
};

export const getFullCourseName = (courseName, subCourseName) => {
    let fullCourseName;
    if (subCourseName) {
        fullCourseName = `${courseName} - ${subCourseName}`;
    } else {
        fullCourseName = courseName;
    }
    return fullCourseName;
};
